import { lowerSlugify } from '../slugify'
import departmentMapping from './depts_mapping.json'

const asin = Math.asin
const cos = Math.cos
const sin = Math.sin
const PI_180 = Math.PI / 180

/**
 * Department name to number
 */
function getDepartmentNumber (department) {
  const result = departmentMapping[department]
  if (!result) {
    return
  }
  return result
}

function getAddressObject (addressComponents) {
  const ShouldBeComponent = {
    number: ['street_number'],
    postal_code: ['postal_code'],
    street: ['street_address', 'route'],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5'
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4'
    ],
    department: ['administrative_area_level_2'],
    country: ['country']
  }

  const address = {
    number: '',
    postal_code: '',
    street: '',
    region: '',
    city: '',
    department: '',
    country: ''
  }
  addressComponents.forEach((component) => {
    for (const shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].includes(component.types[0])) {
        address[shouldBe] = component.long_name
      }
    }
  })
  return {
    ...address,
    department_number: getDepartmentNumber(lowerSlugify(address.department))
  }
}

function hav (x: number) {
  const s = sin(x / 2)
  return s * s
}

function relativeHaversineDistance (lat1: number, lon1: number, lat2: number, lon2: number) {
  const aLatRad = lat1 * PI_180
  const bLatRad = lat2 * PI_180
  const aLngRad = lon1 * PI_180
  const bLngRad = lon2 * PI_180

  const ht = hav(bLatRad - aLatRad) + cos(aLatRad) * cos(bLatRad) * hav(bLngRad - aLngRad)
  // since we're only interested in relative differences,
  // there is no need to multiply by earth radius or to sqrt the squared differences
  return asin(ht)
}

export default {
  getAddressObject,
  getDepartmentNumber,
  relativeHaversineDistance
}
